<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-card-title class="mb-1">
          Two Factor Authentication
        </b-card-title>

        <!-- form -->
        <validation-observer
          ref="verifyLoginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="authenticate"
          >
            <!-- email -->
            <b-form-group
              label-for="loginVerification"
              label="Please enter the code sent to your phone number to verify"
            >
              <validation-provider
                #default="{ errors }"
                name="phone verification code"
                rules="required"
              >
                <b-form-input
                  id="verification_code"
                  v-model="twoFactorCode"
                  name="Login Verification Code"
                  :state="errors.length > 0 ? false:null"
                  placeholder="*****"
                  autofocus
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid || isLoading"
            >
              Submit
              <b-spinner
                v-if="isLoading"
                small
              />
            </b-button>
          </b-form>
        </validation-observer>
        <b-card-text class="text-center mt-2">
          <span>Did not receive code? </span>
          <b-link @click="resendLoginVerificationCode">
            <span>Click to Resend</span>
          </b-link>
          <b-spinner
            v-if="isResendingCode"
            small
            class="ml-1"
          />
        </b-card-text>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  name: 'TwoFactorAuthentication',
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BSpinner,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      twoFactorCode: '',
      status: '',
      // validation rules
      required,

      isLoading: false,
      isResendingCode: false,
      tracking_number: '',
    }
  },
  methods: {
    authenticate() {
      this.isLoading = true
      this.$http.post('membership/auth/two-factor/verify', { code: this.twoFactorCode })
        .then(response => {
          const {
            member,
            token,
            permissions,
            notifications,
          } = response.data

          useJwt.setToken(token)
          localStorage.setItem('user', JSON.stringify(member))
          localStorage.setItem('permissions', JSON.stringify(permissions))
          const abilities = []
          permissions.forEach(ability => {
            abilities.push({
              action: ability.split(' ')[1],
              subject: ability.split(' ')[0],
            })
          })
          this.$ability.update(abilities)
          if (notifications.length) {
            const data = notifications[0]
            this.$router.replace({ name: data.link })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: data.message,
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          } else {
            this.$router.replace(getHomeRouteForLoggedInUser(permissions[0]))
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login Successful!',
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    resendLoginVerificationCode() {
      this.isLoading = true
      this.$http.get(`membership/auth/${this.$router.currentRoute.params.id}/two-factor/resend`)
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.message,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top=right',
            props: {
              title: error.response.data,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style scoped>

</style>
